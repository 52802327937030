import "../App.css";
import "../css/connect.css";
import { Link } from "react-router-dom";

export function Connect() {
  return (
    <>
      <main>
        <section className="email">
          <h1>Connect</h1>
          <a href="mailto: rydodesign@gmail.com">RyDoDesign@gmail.com</a>
        </section>
      </main>
    </>
  )
}